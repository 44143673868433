import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {
  dhlBasicOrange,
  dhlBlack,
  dhlBlue,
  dhlGrey,
  dhlRedButton,
  navigationPushAction,
} from '@smartops/smartops-shared';
import I18n from '../features/I18n';
import {useDispatch, useSelector} from 'react-redux';
import {getAppsDetailsSelector} from '../flows/apps';
import {App} from '../types';
import React, {useEffect, useState} from 'react';
import {NotificationGroupData, ApplicationNotificationData, NotificationFilter} from '../utils/types';
import {
  getApplicationNotifications,
  getAreNotificationFromPhase2VisibleToEveryone,
  getNotificationFiler,
  getNotificationTitleLengthLimit,
  setNotificationFilter,
} from '../flows/applicationNotification';
import {getSettingsSelector, ParentSettings} from '../flows/settings';
import AppOpener from '../features/AppOpener';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import NotificationGroup from '../component/NotificationGroup';
import Icon from 'react-native-vector-icons/FontAwesome';
import Moment from 'moment';
import {logd} from '../features/logging';
import {ALL_OPTION} from './NotificationFilterScreen';
import i18n from '../features/I18n';
import {getAndroidLocale, getIs24HourFormat} from '../flows/app';

export const NOTIFICATIONS_APP_LABELS_FROM_PHASE_1 = ['inc_mng', 'indApr', '306090'];

interface AppState {
  app: App | undefined;
  notificationGroup: NotificationGroupData;
}

const NotificationsScreen = () => {
  const dispatch = useDispatch();
  const [notificationsPhase2, setNotificationsPhase2] = useState<AppState[]>([]);
  const [notificationsFromPhase1, setNotificationsFromPhase1] = useState<AppState[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const appsDetails: App[] = useSelector(getAppsDetailsSelector);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const applicationNotifications: ApplicationNotificationData = useSelector(getApplicationNotifications);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const androidLocale: string | undefined = useSelector(getAndroidLocale);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const is24HourFormat: boolean | undefined = useSelector(getIs24HourFormat);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const settings: ParentSettings = useSelector(getSettingsSelector);
  const titleLengthLimit: number = useSelector(getNotificationTitleLengthLimit);
  const notificationFilter: NotificationFilter | undefined = useSelector(getNotificationFiler);
  const areNotificationsFromPhase2VisibleToEveryone = useSelector(getAreNotificationFromPhase2VisibleToEveryone);

  useEffect(() => {
    const filteredNotificationsPhase1: AppState[] = appsDetails
      .filter(
        it =>
          NOTIFICATIONS_APP_LABELS_FROM_PHASE_1.includes(it.key) &&
          applicationNotifications.notificationGroups.find(
            notificationGroup => notificationGroup.appKey === `${it.key}_phase_1`,
          ),
      )
      .map(it => {
        const unitedState: AppState = {
          app: it,
          notificationGroup: applicationNotifications.notificationGroups.find(
            notificationGroup => `${it.key}_phase_1` === notificationGroup.appKey,
          )!,
        };
        return unitedState;
      })
      .filter(it => {
        // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-unsafe-assignment
        const allowedZeroGroupVisibility: any =
          (settings &&
            settings[it.notificationGroup?.appKey?.replace('_phase_1', '') + '.empty_notification_group_visibility']) ||
          undefined;

        const isAllowedToDisplayZeroValueNotifications =
          it.notificationGroup?.countOfNotifications === 0 && allowedZeroGroupVisibility === 'true';

        const isValueBiggerThenZero = it.notificationGroup && it.notificationGroup.countOfNotifications > 0;
        const notificationsAreNotPresent =
          it.notificationGroup.notifications === undefined || it.notificationGroup.notifications.length === 0;
        let isAllowedToShowByFilter = true;
        if (notificationFilter?.typesLabels) {
          const appKey = it.notificationGroup.appKey.replace('_phase_1', '');
          isAllowedToShowByFilter =
            notificationFilter.typesLabels.includes(ALL_OPTION) || notificationFilter.typesLabels.includes(appKey);
        }
        return (
          it.notificationGroup?.active &&
          (isValueBiggerThenZero || isAllowedToDisplayZeroValueNotifications) &&
          notificationsAreNotPresent &&
          isAllowedToShowByFilter
        );
      });

    setNotificationsFromPhase1(filteredNotificationsPhase1);
  }, [notificationFilter, appsDetails, settings, applicationNotifications]);

  useEffect(() => {
    const notificationsPhase2Data = applicationNotifications.notificationGroups
      .filter(it => it.notifications && it.notifications.length > 0)
      .map(it => {
        const appKeyFromLaunch = it.notifications
          ?.map(it => it.appTrigger?.launches)
          ?.flatMap(it => it)
          ?.map(it => it?.app_label)
          ?.find(it => it);

        const unitedState: AppState = {
          app: appsDetails.find(it => it.key === appKeyFromLaunch),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          notificationGroup: Object.create(it), /// copy to not mutate the state
        };
        return unitedState;
      });

    if (notificationFilter) {
      logd('Apply filter: ' + JSON.stringify(notificationFilter));
      applyFilter(notificationsPhase2Data);
    }
    setNotificationsPhase2([
      ...notificationsPhase2Data.filter(
        it =>
          it.notificationGroup.active &&
          it.notificationGroup.notifications &&
          it.notificationGroup.notifications.length > 0,
      ),
    ]);
  }, [notificationFilter, applicationNotifications]);

  const applyFilter = (input: AppState[]) => {
    input
      .map(it => {
        if (notificationFilter?.typesLabels && it.notificationGroup.appKey) {
          const appKey = it?.notificationGroup.appKey;
          it.notificationGroup.active =
            (notificationFilter && notificationFilter.typesLabels.includes(ALL_OPTION)) ||
            notificationFilter.typesLabels.includes(appKey);
        }
        return it;
      })
      .map(it => {
        it.notificationGroup.notifications = it?.notificationGroup?.notifications?.filter(notifData => {
          let isMatching = true;
          if (notificationFilter?.searchText) {
            const searchString = notificationFilter.searchText.toLowerCase();
            isMatching =
              isMatching &&
              (notifData.description?.toLocaleLowerCase().includes(searchString) === true ||
                notifData.title?.toLowerCase().includes(searchString) === true);
          }

          if (notificationFilter?.severityLabels && notifData.severity && notifData.severity.label) {
            isMatching =
              isMatching &&
              (notificationFilter.severityLabels.includes(ALL_OPTION) ||
                notificationFilter.severityLabels.includes(notifData.severity.label));
          }

          if (notificationFilter?.from && notifData.updated) {
            isMatching = isMatching && notificationFilter.from <= Moment(notifData.updated).toDate();
          }

          if (notificationFilter?.to && notifData.updated) {
            isMatching = isMatching && notificationFilter.to >= Moment(notifData.updated).toDate();
          }

          isMatching = isMatching && it.notificationGroup.active;

          return isMatching;
        });
      });
  };

  return (
    <ScrollView style={styles.container}>
      <View style={{flexDirection: 'row', alignSelf: 'flex-end', marginTop: 15}}>
        {notificationFilter && (
          <TouchableOpacity style={styles.clearButton} onPress={() => dispatch(setNotificationFilter(undefined))}>
            <Text style={styles.clearButtonTitle}>{i18n.t('notifications.clear_filter')}</Text>
          </TouchableOpacity>
        )}
        {areNotificationsFromPhase2VisibleToEveryone && (
          <TouchableOpacity
            style={styles.filterIcon}
            onPress={() => dispatch(navigationPushAction('notificationFilterScreen'))}>
            <Icon
              name={'sliders'}
              size={30}
              style={{alignSelf: 'center'}}
              color={notificationFilter ? dhlBlue : dhlGrey}
            />
          </TouchableOpacity>
        )}
      </View>
      {[...notificationsFromPhase1, ...notificationsPhase2].length != 0 ? (
        [...notificationsFromPhase1, ...notificationsPhase2]
          .sort(
            (a, b) =>
              (a.notificationGroup?.settings?.order ? a.notificationGroup?.settings?.order : -1) -
              (b.notificationGroup?.settings?.order ? b.notificationGroup?.settings?.order : -1),
          )
          .map((item: AppState) => {
            return (
              <AppOpener app={item.app} key={item.notificationGroup?.appKey} testId={item.notificationGroup?.appKey}>
                <NotificationGroup
                  app={item.app}
                  item={item.notificationGroup}
                  androidLocale={androidLocale}
                  is24HourFormat={is24HourFormat}
                  titleLengthLimit={titleLengthLimit}
                />
              </AppOpener>
            );
          })
      ) : (
        <View>
          <View style={styles.bellIconWrapper}>
            <FontAwesome5Pro name={'bell'} size={100} color={dhlGrey} />
          </View>
          <Text style={styles.emptyNotificationsText}>{I18n.t('notifications.no_notifications')}</Text>
        </View>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  appWrapper: {
    flexDirection: 'row',
    height: 50,
    marginLeft: 5,
    marginTop: 10,
    alignItems: 'center',
  },
  yellowBar: {
    borderRadius: 10,
    height: '100%',
    width: 6,
    backgroundColor: dhlBasicOrange,
    marginRight: 10,
  },
  notificationNumber: {
    color: dhlRedButton,
    fontSize: 18,
    fontWeight: 'bold',
    marginHorizontal: 10,
  },
  notificationText: {
    color: dhlBlack,
    fontSize: 18,
    fontWeight: 'bold',
    flexWrap: 'wrap',
    textAlign: 'left',
    width: '72%',
  },
  emptyNotificationsText: {
    color: dhlBlack,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bellIconWrapper: {
    paddingTop: 30,
    paddingBottom: 20,
    alignItems: 'center',
  },
  bellIcon: {
    // @ts-ignore
    fontSize: '20@ms0.2',
  },
  filterIcon: {
    padding: 10,
    width: 50,
  },
  clearButton: {
    alignSelf: 'center',
    padding: 10,
    justifyContent: 'center',
    marginRight: 5,
  },
  clearButtonTitle: {
    color: dhlRedButton,
    fontWeight: 'bold',
  },
});

export default NotificationsScreen;

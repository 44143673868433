import {ACTIVE_ENV} from 'configs/active-env';
import {loadValueMock, saveValueMock} from 'mock/intfMock';
import {getMswPreferenceItem, setMswPreferenceItem} from '@smartops/react-native-multiprocess-preferences';
import {loadValueFromBrowser, saveValueToBrowser} from 'features/webStorage';
import {isWeb} from 'features/platformSpecific';

export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_REFRESH_TOKEN = 'refresh_token';
export const KEY_LOGIN = 'username';
export const KEY_SMARTREM_ID = 'smartrem_id';
export const KEY_FIRST_NAME = 'firstName';
export const KEY_LAST_NAME = 'lastName';
export const KEY_LAST_LOGIN = 'last_login';
export const KEY_LANGUAGE = 'locale';
export const KEY_USER_EMAIL = 'user_email';
export const KEY_USER_GROUP_IDS = 'user_group_ids';
export const KEY_AUTH_TYPE = 'auth_type';
export const KEY_IS_ADMIN = 'is_admin';
export const KEY_IS_LOGIN_PROCESS_COMPLETED = 'is_login_completed';
export const KEY_SELECTED_USERGROUP_ID = 'selected_usergroup_id';
export const KEY_USER_GROUPS = 'user_groups';
export const KEY_GROUP_ATTRIBUTES_VALUE_NAMES = 'attribute_value_names';
export const KEY_IS_ALLOWED_TO_SHOW_BOTTOM_BAR_MENU = 'is_allowed_to_show_bottom_bar_menu';
export const KEY_LOGICS_EMPLOYEE_ID = 'logics_employee_id';

export const KEY_GROUP_IDS_FACILITIES_MAP = 'group_ids_facilities_map';
export const KEY_GROUP_IDS_COUNTRIES_MAP = 'group_ids_countries_map';
export const KEY_GROUPS_APPS_FACILITIES_MAP = 'groups_apps_facilities_map';
export const KEY_GROUPS_APPS_COUNTRIES_MAP = 'groups_apps_countries_map';

export const KEY_USER_ROLES = 'userRoles'; // obsolete
export const KEY_USER_APPS = 'userApps'; // just duplicated info in more meaningful format
export const KEY_USER_JSON_RESP = 'userJsonResp'; // the user details json response as it was fetched
export const KEY_USER_APPS_UM_RESPONSE = 'userAppsFromUm';
export const KEY_APPS_DETAILS = 'appsDetails';
export const KEY_FAVORITE_APPS_KEYS = 'favoriteApps';
export const KEY_IS_TABLET = 'isTablet';
export const KEY_EMERGENCY_MODE = 'emergencyMode';
export const KEY_SHOW_NUMPAD = 'showNumpad';
export const KEY_WEB_PATHS = 'webPaths';
export const SELECTED_USER_GROUP_OBJECT = 'SELECTED_USER_GROUP_OBJECT';

export const KEY_AVAILABLE_FILES = 'availableFiles';

export const KEY_APP_SETTINGS = 'appSettings';
export const KEY_WMS_SSO_INIT_DATA = 'wmsSsoInitData';

export const KEY_USER_PASSWORDS = 'user_passwords';
export const KEY_USER_PASSWORD = 'key_user_password';
export const KEY_LOCKSCREEN_DATA = 'PARENT_LOGGED_USER_DATA';
export const KEY_LOCKSCREEN_LOCKED = 'com.dhl.smartops.lockscreen.locked';
export const KEY_LOCKSCREEN_SHOWUP = 'lockscreen_showup';
export const KEY_BUSINESS_RELEASE = 'business_release'; //To be used by child apps
export const KEY_BR_SWITCH_SCREEN = 'br_switch_screen'; //If a screen is set, then we do BR switch (null | pageLogin | userGroupManagementScreen)
export const KEY_USER_BY_LOGIN_DATA = 'user_by_login_data'; //Persist userJson during BR switch
export const KEY_METRICS_EVENT_SESSION_ID = 'MetricsEventSessionIdValue';
export const KEY_METRICS_PARENT_VERSION = 'parent_app_version';
export const KEY_USER_GROUP_ID = 'MetricsUserGroupID';

export const KEY_PARENT_APP_VERSION = 'parent_app_version';

export const KEY_API_GW_URL = 'apiGwUrl';
export const KEY_API_GW_URL_CLEAN = 'apiGwUrl_clean';

export const KEY_CLIENT_ID = 'client_id';
export const KEY_CLIENT_SECRET = 'client_secret';
export const KEY_IS_LOGGING_INTO_WEB_CONSOLE_ENABLED = 'isLoggingIntoWebBrowserConsoleEnabled';

export const KEY_TRANSLATIONS = language => `${language}translations`;
export const KEY_TRANSLATIONS_TIMESTAMP = language => `${language}translationsTimestamp`;
const areWeTestingWithJest = () => process.env.JEST_WORKER_ID !== undefined;

export const saveValue =
  ACTIVE_ENV.mockSharedPrefs === true ? saveValueMock : isWeb ? saveValueToBrowser : setMswPreferenceItem;
export const loadValue =
  ACTIVE_ENV.mockSharedPrefs === true ? loadValueMock : isWeb ? loadValueFromBrowser : getMswPreferenceItem;

export const loadJson = async key => {
  let value;
  if (ACTIVE_ENV.mockSharedPrefs || areWeTestingWithJest()) {
    value = await loadValueMock(key);
  } else if (isWeb) {
    value = await loadValueFromBrowser(key);
  } else {
    value = await getMswPreferenceItem(key);
  }
  return value ? JSON.parse(value) : value;
};

export const ACTIVE_ENV = {
  newGatewayUrl: 'https://dsc.api-sandbox.dhl.com',
  newClientId: 'UpTMuF15XGZBiKiYhzWqFCauVvCWmSlD',
  newClientSecret: 'SThF3Jq8YzUhdGqH',

  gatewayUrl: 'https://dsc.api-sandbox.dhl.com/dhllink',
  isLoggingIntoWebBrowserConsoleEnabled: false,
};

export const RELEASE_DATE = '__CURRENT_DATE__'; // REPLACED DURING GRADLE BUILD
